import "./App.css";
import FartTime from "./components/FartTime/FartTime";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import MehMehnomics from "./components/MehMehnomics/MehMehnomics";

function App() {
  return (
    <div>
      <Hero />
      <MehMehnomics />
      <FartTime />
      <Footer />
    </div>
  );
}

export default App;
