import React from "react";
import "./footer.css";
import Boy from "../../assets/footerboy.svg";
import Twitter from "../../assets/twitter.svg";
import Telegram from "../../assets/telegram.svg";
import Discord from "../../assets/discord.svg";
import DexToools from "../../assets/DEXTools_icon.png";

const Footer = () => {
  return (
    <div className="farttime-container footer1">
      <div className="content-container footer">
        <div className="footer-container">
          <div className="footer-content">
            <div className="footer-desc">
              <span>MehMeh</span>
              <h2>Let’s fart our way to the moon</h2>
            </div>
            <div className="social-links-container">
              <div className="social-box">
                <a href="https://www.dextools.io/app/en/solana/pair-explorer/DEPVrowMuFSaimFXFksC3QPNo5zrXUiTQajQtG6HZ1mK?t=1717503475210">
                  <img src={DexToools} alt="DexToools" />
                </a>
              </div>
              <div className="social-box">
                <a href="https://twitter.com/MehMehStory">
                  <img src={Twitter} alt="twitter" />
                </a>
              </div>
              <div className="social-box">
                <a href="https://t.me/mehmehflock">
                  <img src={Telegram} alt="Telegram" />
                </a>
              </div>
              {/* <div className="social-box">
                <a href="/">
                  <img src={Discord} alt="twitter" />
                </a>
              </div> */}
            </div>
          </div>
          <img src={Boy} alt="boy" className="footer-boy" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
