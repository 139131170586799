import React from "react";
import "./hero.css";
import HeroImage from "../../assets/heroImage.svg";
import Twitter from "../../assets/twitter.svg";

const Hero = () => {
  return (
    <>
      <div className="hero-container">
        <div className="content-container">
          <div className="title-container">
            <h1 className="title-mehmeh">MehMeh</h1>
          </div>
          <div className="content">
            <div className="image-container">
              <img
                src={HeroImage}
                alt="boy"
                className="img-hero"
                width={320}
                height={407}
              />
            </div>
            <div className="desc-container">
              <p className="desc">
                In the village of Woolbury, Mehmeh, an ordinary sheep with an
                insatiable appetite, began unleashing extraordinarily powerful
                farts that launched him skyward, carrying him towards the moon
                and leaving the astonished villagers in awe of his incredible
                journey. From that moment on, Mehmeh became a legend, inspiring
                generations of sheep to reach for the moon, one fart at a time.
              </p>
            </div>
          </div>
        </div>
        <div className="joinflock-container">
          <div className="content-container">
            <a
              href="https://twitter.com/MehMehStory"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className="flock-container">
                <img src={Twitter} alt="Twitter" />
                <h3>Join the flock</h3>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
