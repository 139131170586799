import React from "react";
import "./mehmehnomics.css";
import BoyImage from "../../assets/MehMehnomics.svg";

const MehMehnomics = () => {
  return (
    <div className="mehmehnomics-container">
      <div className="content-container">
        <div className="content-meh">
          <h1>
            MehMeh<span> nomics</span>
          </h1>
          <div className="boxes-container">
            <div className="box-container">
              <h3>Total Supply</h3>
              <h4>100M</h4>
            </div>
            <div className="box-container">
              <h3>Mint & Freeze</h3>
              <h4>REVOKED</h4>
            </div>
            <div className="box-container">
              <h3>Liquidity</h3>
              <h4>BURNED</h4>
            </div>
            <div className="box-container">
              <h3>Taxes</h3>
              <h4>0/0</h4>
            </div>
          </div>
          <img src={BoyImage} alt="boy" className="boy-image" />
        </div>
      </div>
    </div>
  );
};

export default MehMehnomics;
