import React from "react";
import "./farttime.css";

const FartTime = () => {
  return (
    <div className="farttime-container">
      <div className="content-container">
        <div className="content-farttime">
          <h3>One fart at a time.</h3>
          <div className="boxes-farttime">
            <div className="box-farttime">
              <h1>FART 01</h1>
              <p>
                - Presale
                <br />
                - Deploy liquidity
                <br />
                - Burn LP tokens
                <br />- Reach 500+ Holders
              </p>
            </div>
            <div className="box-farttime">
              <h1>FART 02</h1>
              <p>
                - Coingecko/ Coinmarketcap listing
                <br />
                - Flock marketing
                <br />
                - Partnerships
                <br />- Reach 1,000+ holders
              </p>
            </div>
            <div className="box-farttime">
              <h1>FART 03</h1>
              <p>
                - CEX listing
                <br />
                - Multichain bridging
                <br />
                - 10,000+ holders
                <br />- Fart Bitcoin behind
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FartTime;
